import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
// import dragVerify  from "vue-drag-verify2";
import 'element-plus/theme-chalk/index.css'
import * as Icons from '@element-plus/icons-vue';
import './assets/css/global.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
const app = createApp(App)
Object.keys(Icons).forEach(key => {
    app.component(key,Icons[key])
});
app.use(router)
    .use(ElementPlus,{locale:zhCn})
    // .use(dragVerify)
    .mount('#app')